@use "mixins" as *;
@use "vars" as *;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  @include compact;
  scroll-behavior: smooth;
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-header;
  color: $dark;
  text-transform: lowercase;
  font-weight: 700;
  font-size: calc(1.5rem + .5vw);
}

body,
a {
  font-family: $font-body;
  color: $dark;
  font-size: calc(1rem + .15vw);
  text-decoration: none;
}

a:hover, a:focus, a:active {
  text-decoration: underline;
}

h1, h2 {
  font-size: calc(2rem + 1vw);
}