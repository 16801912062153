@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.main {

    .home-grid {
        margin: 2vh auto;
        h2 {
            @include brushBG(auto, left);
            margin-bottom: 4vh;
        }
    }
    .mainHome {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 2vw;
    
        .home-cta {
            margin-top: 2vh;
            text-align: center;
        }
    
        &>* {
            width: 100%;
            @include compact;
        }

        .main-photo {
            margin: auto;
            margin-bottom: 2vh;
        }
    
        h2 {
            text-align: center;
            font-size: calc(2.5rem + 2vw);
            order: -1;
        }
    
        .wpHome {
            h2 {
                display: none;
            }
    
            ul {
                @include compact;
    
                li {
                    list-style: none;
                }
            }
    
            ul:first-of-type {
                li {
                    display: inline;
                    font-weight: 700;
                    font-size: calc(1.25rem + .25vw);
    
                    &:not(:last-child):after {
                        content: " | ";
                    }
                }
            }
    
            ul:last-of-type {
                li {
                    border: 2px solid $dark;
                    padding: .5rem;
                    color: $dark;
                    width: fit-content;
                    display: inline-block;
                    margin: .5rem .5rem .5rem 0;
                    font-weight: 700;
                }
            }
    
            h3 {
                color: $blue;
                font-size: calc(1.5rem + .75vw);
                margin: 1rem auto;
            }
        }
    
    
        @media screen and (min-width: 50em) {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-auto-rows: min-content;
            gap: 2rem;
    
            .home-cta {
                grid-row: 3/4;
                grid-column: 2/3;
                text-align: right;
                @include compact;
                align-self: center;
            }
    
            .main-photo {
                grid-column: 1/2;
                grid-row: 1/4;
            }
    
            h2,
            .wpHome {
                grid-column: 2/3;
            }
    
            h2 {
                grid-row: 1/2;
                text-align: left;
                margin: 0;
                align-self: center;
            }
    
            .wpHome {
                grid-row: 2/4;
            }
        }
    }

    @media screen and (min-width: 50em) {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        gap: calc(2rem + 2vw);
    }
}
