@use "../../styles/vars" as *;

.loading {
    text-align: center;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
    }

    @keyframes turn {

        25% {
            transform: rotate(90deg);
        }

        50% {
            transform: rotate(180deg);
        }

        75% {
            transform: rotate(270deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }


    svg.loadface {
        animation: turn 1.6s linear infinite;
        min-height: 4.5rem;
        max-height: 12.5rem;
        margin: 1rem;
        padding: 0;

        path.st0 {
            fill: #D3A798;
            stroke: none;
        }

        g ellipse.st1 {
            fill: #D27B72;
        }

        path#nose-bridge {
            fill: #D3A798;
            stroke: none;
        }

        path.st2 {
            fill: #AA867D;
            stroke: none;
        }

        path.st3 {
            display: block;
        }

        path.st4 {
            fill: #4E0904;
        }

        g path.st8 {
            fill: $var-color;
        }

    }
}