@use "vars" as *;
@use "mixins" as *;

    a.btn {
        display: inline-block;
        text-align: center;
        margin: 0;
    }

    a.primary-btn, div.primary-btn a  {
        @include btnStyle;
        width: fit-content;

        &:hover,
        &:focus,
        &:active {
            @include btnHover;
        }
    }

    a.secondary-btn {
        &:nth-child(2) {
            @include btnStyle(true, $green);

            &:hover,
            &:focus,
            &:active {
                border-color: $blue;
                color: $blue;
            }

        }
    }