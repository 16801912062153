@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

section#toolkit {
    h2 {
        @include brushBG(70%);

        @media screen and (min-width: 25em) {
            @include brushBG(auto, -15%);
        }

    }
}

section#about-gist {
    h2 {
        @include brushBG;
        @media screen and (min-width: 25em) {
            @include brushBG(auto, left);
        }
    }

    svg.about-face {
        margin: 1rem;
        padding: 0;

        path.st0#face {
            fill: #D3A798;
            stroke: none;
        }

        g#cheeks ellipse.st1 {
            fill: #D27B72;
        }

        path#nose-bridge {
            fill: #D3A798;
            stroke: none;
        }

        path#nose-shadow.st2 {
            fill: #AA867D;
            stroke: none;
        }

        path#nose.st3 {
            display: block;
        }

        path#brow.st4 {
            fill: #4E0904;
        }

        g#hair path.st8 {
            fill: $var-color;
        }
    }

    svg.left-face,
    svg.right-face {

        path {
            stroke: none;
        }

        path.st0 {
            fill: #D3A798;
        }

        path.st2 {
            fill: #D27B72;

        }

        path.st7 {
            fill: #030303;

        }
        path.st9 {
            fill: $var-color;
        }
    }

    svg.left-face {
        margin-left: -5vw;

        path.st1 {
            fill: #D3A798;
        }

        path.st3 {
            display: block;
            fill: #AA867D;
        }

        path.st4 {
            fill: #E5BDB3;
        }

        path.st6 {
            fill: #4E0904;
        }

        path.st7 {
            fill: #F5F4F3;
        }

        path.st8 {
            fill: #030303;
        }
    }

    svg.right-face {
        margin-right: -5vw;

        path.st1 {
            fill: #D27B72;
        }

        path.st2#shadow {
            fill: #AA867D;
        }

        path.st3 {
            display: block;
            fill: #E5BDB3;
        }

        path.st4 {
            fill: #4E0904;
        }
    }

    @media screen and (min-width: 50em) {
        display: grid;
        grid-template-columns: 1fr 10fr 1fr;
        gap: 2vw;
        align-items: center;
        margin-bottom: 5vh;
    }

    @media screen and (min-width: 80em) {
        svg.left-face {
            margin-left: -10vw;
        }

        svg.right-face {
            margin-right: -10vw;
        }
    }
}