@use "vars" as *;

@mixin compact {
    padding: 0;
    margin: 0;
}

@mixin btnStyle($outline: false,
    $def: $blue,
    $text: $light, $pad: .75rem 1.5rem) {

    padding: $pad;
    margin: .5rem;
    border-radius: 5rem;
    background-color: $def;
    border: 2px solid $def;
    color: $text;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;

    @if $outline ==true {
        background-color: $light;
        color: $def;
    }
}

@mixin btnHover($outline: false, $h: $green) {
    background-color: $h;
    border-color: $h;

    @if outline==true {
        background-color: $light;
        border-color: $h;
        color: $h;
    }
}

@mixin brushBG($size: 100%, $pos: -50% 50%) {
    @include compact;
    background-image: url("../../media/brushstroke.svg");
    background-position: $pos;
    background-repeat: no-repeat;
    background-size: $size;
}