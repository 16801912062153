@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.work-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;

    a.view-svg {
        align-self: center;
        width: fit-content;
        margin: 1rem;
        font-weight: 700;
        font-size: calc(1rem + .5vw);

        svg {
            width: 4rem;
            margin: auto;
            path {
                fill: $blue;
            }
        }

        &:hover, &:focus, &:active {
            
            svg path {
                fill: $green;
            }
        }

        p {
            @include compact;
        }
    }

    .btn-container {
        width: 100%;
        text-align: center;
        a.primary-btn {
            display: block;
            width: 15rem;
            margin: 2vh auto;
        }
    }

    article.work-item {
        max-width: 25vw;
        min-width: 15rem;
        padding: 1.5rem;
        background-color: $light;
        border-radius: 2rem;
        filter: drop-shadow(.15rem .25rem .5rem rgba(0, 0, 0, .15));
        transition: all .25s ease-in-out;


        figure {
            width: 100%;
            margin: auto;

            img, video {
                width: 100%;
                height: auto;
                margin: auto;
            }
        }

        a {

            h3 {
                text-transform: none;
                font-size: 1.5rem;
            }

        }

    }

    @media screen and (min-width: 50em) {
        display: grid;
        grid-template-columns: repeat(3, 2fr) 1fr;
        gap: 2rem;

        h3 {
            font-size: calc(1.5rem + .5vw);
        }

        article.work-item {
            &:hover,
            &:focus {
                transform: scale(1.05);
            }
        }
    }
}