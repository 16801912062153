@use "../../styles/mixins" as *;
@use "../../styles/vars" as *;

footer {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    padding-bottom: 5rem;
    background-color: $dark;

    a,
    p {
        color: $light;
        vertical-align: middle;
        align-self: center;
        @include compact;
    }

    a {
        svg.footer-logo {
            display: inline-block;
            height: 2rem;
            

            path {
                fill: $light;
                stroke: $light;
            }
        }
    }

    @media screen and (min-width: 50em) {
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        padding: 2vh 5vw;

        a svg.footer-logo{
            height: calc(2rem + .5vh);
        }

        a p:hover, p:focus {
            color: $yellow;
            svg.footer-logo {
                path {
                    fill: $yellow;
                    stroke: $yellow;
                }
            }
        }
    }

    @media screen and (min-width: 80em) {
        padding: 2vh 10vw;
    }
}