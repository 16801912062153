@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.work-content {
    .entry-content {
        margin-top: 2.5rem;

        figure {
            @include compact;
            text-align: center;
            max-width: 35rem;

            img, video {
                width: 100%;
                height: auto;
                margin: auto;
            }
        }

        .wp-block-buttons {
            text-align: center;
            margin: 2rem 1rem;
        }

        .wp-block-button {
            margin: 1rem auto;
        }

        @media screen and (min-width: 50em) {
            display: flex;
            flex-direction: column;

            &>* {
                width: 100%;
            }

            &>div {
                display: flex;
                gap: 1.5rem;

                video {
                    width: 60%;
                }
                figure {
                    width: 40%;
                    img {
                        width: 100%;
                        max-width: 600px;
                    }
                }

                & > div {
                    width: 60%;
                    align-self: center;

                    .wp-block-buttons {
                        width: 100%;
                        text-align: right;
                        margin: 2.5rem 0;

                        .wp-block-button, .wp-block-button a {
                            margin: 1rem 0;
                            text-align: right;
                        }
                    }
                }
            }


        }
    }

    div.btn-container {
        text-align: center;
        margin: 1.5rem auto;

        a.primary-btn {
            margin: 1rem auto;
        }
    }

    h2,
    h3,
    h4 {
        @include compact;
    }

    h2 {
        color: $blue;
    }

    h3 {
        color: $green;
        margin: 1.5rem auto;
    }


    .work-details {
        margin: 2rem 1rem;

        &>div:not(:last-child) {
            border-bottom: 2px solid $var-color;
        }

        .acc-btn {
            background-color: $light;
            color: $dark;
            cursor: pointer;
            padding: 1.25rem;
            width: 100%;

            p {
                font-weight: 700;
            }
        }

        .panel {
            padding: 1.25rem;

            caption {
                font-style: italic;
            }

            iframe {
                width: 100%;
                margin: 1rem auto;


                &.floatFrame {

                    @media screen and (min-width: 25em) {
                        width: auto;
                        float: left;
                        margin: .5rem 1rem 2rem 1rem;
                    }
                }
            }

            img {
                width: 100%;
                max-width: 20rem;
                height: auto;
                display: inline;

                @media screen and (min-width: 25em) {
                    float: left;

                    &.fullImg {
                        float: none;
                        width: 60%;
                        max-width: none;
                        margin: 1rem auto;
                        display: block;
                    }
                }

                
            }

            
           video {
                width: 100%;
                height: auto;
                display: inline;

                @media screen and (min-width: 25em) {
                    max-width: 30%;
                    float: left;
                    margin: 1rem;
                }
            }
           
            h4 {
                color: $green;
            }
        }

        svg path {
            fill: $dark;
        }
    }
}

.details-content {
    @media screen and (min-width: 50em) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, auto);
        text-align: left;
        margin: 2rem auto;

        &>* {
            grid-column: 1/3;
        }

        h3 {
            grid-row: 1/2;
            margin: 1.5rem 0;
        }

        .work-details {
            grid-row: 2/3;
        }

        .btn-container {
            grid-row: 3/4;
        }

    }
}

// Post Specific Styles