@use "../../styles/mixins" as *;
@use "../../styles/vars" as *;

.skills-isotope {

    padding: 1rem;
    border-radius: 1.25rem;
    background-color: $light;
    filter: drop-shadow(0 .1rem .5rem rgba(47, 47, 47, .15));
    margin: 1rem auto;

    ul {
        @include compact;

        li {
            list-style-type: none;
            display: inline-block;
        }
    }

    .filter-box {
        margin-bottom: 1rem;

        li {
            padding: .5rem;
            font-size: 1rem;
            font-weight: 700;
            background-color: $dark;
            color: $light;
            cursor: pointer;
            border: 2px solid $light;

            &:hover,
            &:focus,
            &:active,
            &.check {
                @include btnHover(false, $blue);
                border: 2px solid $light;
            }

            @media screen and (min-width: 50em) {
                padding: 1rem;
                font-size: 1.25rem;
            }
        }
    }

    .skills-container li {
        @include btnStyle(true, transparent);
        text-align: left;
        color: $dark;
        margin: .25rem;
        font-size: .75rem;
        background-color: white;

        @media screen and (min-width: 50em) {
            font-size: calc(.75rem + .15vw);
        }
    }

    @media screen and (min-width: 50em) {
        padding: calc(1rem + .2vw);
    }
}