@use "../../styles/mixins" as *;
@use "../../styles/vars" as *;

#site-header {
    width: 100%;
    text-align: center;
    padding: 2vh 1vh;

    h1 {
        visibility: hidden;
        height: 0;
        @include compact;
    }
    a {
        svg#logo {
            .st0 {
                fill: $dark;
            }
    
            .st2 {
                fill: $var-color;
                stroke: rgba(47, 47, 47, .5);
            }
    
            height: calc(2.5rem + 3vh);
            width: auto;
            margin: auto;
        }

    }

    @media screen and (min-width: 50em) {
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 2vh 5vw;

        a svg#logo {
            &:hover {
                    g#eye-open {
                        display: block;
                        
                    }
        
                    g#eye-close {
                        display: none;
                    }
            }
        }
    }

    @media screen and (min-width: 80em) {
        padding: 4vh 10vw;
    }
}