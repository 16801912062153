@use "../../styles/vars" as *;

section.main-photo {

  max-width: 25rem;
  
  &>* {
    margin: auto;
  }

  div.colorInput {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    gap: .5rem;

    input {
      width: 50%;
      height: auto;
    }
  }


  figure {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    margin-top: -25%;



    img#base,
    img#mask {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }

    svg#colorFill {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 5;
      mix-blend-mode: color;
      filter: blur(.3rem);


      path.st0 {
        fill: $var-color;
        stroke: none;
      }

    }

    img#mask {
      mix-blend-mode: luminosity;
      z-index: 10;
    }

    img#base {
      z-index: 0;
    }
  }

  button#reset {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    svg path {
      fill: $blue;
    }

    &:hover, &:focus {
      svg path {
        fill: $green;
      }
    }
  }
}