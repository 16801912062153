@use "../../styles/mixins" as *;
@use "../../styles/vars" as *;

#contact {
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    padding-bottom: 2.5rem;

    h2 {
        text-align: center;
        @include compact;
        margin: 1rem auto;
        font-size: 2rem;
        color: $blue;
    }

    .socials {
        @include compact;
        text-align: center;
        display: flex;
        justify-content: space-evenly;
        gap: 1rem;
        align-items: flex-start;
        flex-wrap: wrap;

        li {
            cursor: pointer;
            list-style: none;
            display: inline-block;


            svg {
                width: 2.5rem;
                height: 2.5rem;
                @include compact;
            }

            svg.mailIcon path {
                fill: $dark;
            }
            
            button {
                background-color: transparent;
            }

            a {

                svg {
                    path {
                        fill: currentColor;
                    }
                }
            }

            span {
                display: block;
            }

            &:hover,
            &:focus {
                svg {
                    path {
                        fill: $blue;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 25em) {
        margin-top: 4vh;
        justify-content: space-evenly;
        align-items: center;

        h2 {
            font-size: calc(2rem + .5vw);
            ;
        }

        .socials {
            align-items: flex-end;
        }


    }
}