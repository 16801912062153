// Fonts
$font-header: "Comfortaa", sans-serif;
$font-body: "Barlow", sans-serif;

// Variable hair color
$hair-color: rgba(212, 0, 201, 1);
$var-color: var(--color-input, $hair-color);

// Brand colors
$light: rgba(246, 242, 255, 1);
$yellow: rgba(243, 255, 71, 1);
$dark: rgba(47, 47, 47, 1);
$blue: rgba(56, 97, 140, 1);
$green: rgba(0, 146, 106, 1);
