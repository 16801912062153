@use "vars" as *;
@use "mixins" as *;

#root {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-image: url("../media/paper-texture-webp.webp");
  background-repeat: repeat;
  background-color: $light;

  .main {
    flex: 1 0 auto;
    padding: 1.5rem;

    @media screen and (min-width: 50em) {
      padding: 2vh 5vw;
    }

    @media screen and (min-width: 80em) {
      padding: 4vh 10vw;
    }
  }

  footer {
    margin-top: auto;
    flex-shrink: 0;
  }
}