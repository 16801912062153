@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.work-intro {
    margin-bottom: 2.5rem;
}

section#work {
    h2 {
        @include brushBG(60% 100%, -40%);

        @media  screen and (min-width: 50em) {
            @include brushBG(auto, -20%)
        }
    }

    .work-gallery {
        @media screen and (min-width: 50em) {
            display: grid;
            grid-template-columns: repeat(3, 2fr);
            gap: 2rem;
    
        }
    }
}

.gallery {
    padding-top: 1.5rem;
    margin-bottom: 2vh;
}


