@use "../../styles/mixins" as *;
@use "../../styles/vars" as *;

nav#nav{
    z-index: 50;

    ul {
        @include compact;
        display: flex;
        gap: 3vw;
    
        li {
            list-style-type: none;
            @include compact;

            a {
                text-decoration: none;
                font-size: calc(1rem + .75vw);
                text-transform: lowercase;
                color: $dark;
                font-weight: 700;
                font-family: $font-header;
                position: relative;
                display: inline-block;
            }
        }
    }
}
.mobile-nav {
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    background-color: white;
    filter: drop-shadow(0 .25rem .5rem rgba(47, 47, 47, .5));
    text-align: center;
    width: 100%;
    padding: calc(1rem + 1vw) calc(1.5rem + 2vw);
    
    ul {
        display: flex;
        justify-content: center;

        li {
            &:not(:last-child) a {
                border-right: 1px solid $dark;
                padding-right: 2vw;
            }
            a {
                
                
                :active, :focus {
                    background-color:  $var-color;
                }
            }
        }
    }
}

nav#nav.web-nav {
    background-color: transparent;
   
    ul {
        li {
            a {
                font-size: calc(1.25rem + .75vw);
                position: relative;

                svg#squiggle {
                    position: absolute;
                    bottom: -.5rem;
                    left: 0;
                    width: 0;
                    height: 1rem;
                    @include compact;
                    z-index: -1;
            

                    & path {
                        stroke: none;
                        fill: $var-color;
                    }
                }

                &:hover, &:focus {

                    svg#squiggle {
                        transition: width .2s ease-in; 
                        width: 100%;
                    }
                }
            }
        }
    }
}